export function seo(data = {}) {
    data.title = data.title || 'Default title';
    data.metaDescription = data.metaDescription || 'Default description';

    document.title = data.title;
    document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
}

export const subDomainInfo = {
    support: {
        title: "Support",
        description: "Provide support to devs and tech team"
    },
    b2b: {
        title: "B2B",
        description: "B2B application for end Organizations",
    }
}
